import type { AppProps } from "next/app";
import { wrapper } from "@/store/store";
import React from "react";
import { Provider, useStore } from "react-redux";
import { appDispatch } from "@/store/hooks";
import { useRouter } from "next/router";
import Head from "next/head";
import Script from "next/script";
import "@/public/static/css/stylesheet.css";
import "../public/static/css/embla.css";

const seoData: Record<
  string,
  { title: string; description: string; keywords: string }
> = {
  "/": {
    title: "ゼニヤタイランド - ZENIYA (THAILAND) CO.,LTD.",
    description: "金属成形・表面処理製品の加工販売",
    keywords: "稲元マーク, エンブレム, 銘板, ネームプレート, ロゴ, バッジ",
  },
  "/en": {
    title: "ZENIYA (THAILAND) CO.,LTD.",
    description:
      "Production and sales of Metal forming and surface treatment products",
    keywords: "Inamoto mark, Emblem, Name plate, Logo, Badge",
  },
  "/th": {
    title: "เซะนิยะ (ไทยแลนด์) จำกัด - ZENIYA (THAILAND) CO.,LTD.",
    description: "ผลิตและจำหน่ายผลิตภัณฑ์ขึ้นรูปโลหะและชุบผิว",
    keywords: "Inamoto mark, Emblem, Name plate, Logo, ป้ายสถานะ,",
  },
};

function MyApp({ Component, pageProps }: AppProps) {
  const store: any = useStore();
  const dispatch = appDispatch();
  const router = useRouter();
  const currentSeo = seoData[router.pathname] || seoData["/en"]; // ใช้ en เป็นค่าเริ่มต้น

  return (
    <Provider store={store}>
      <>
        <Head>
          <title>{currentSeo.title}</title>
          <meta name="description" content={currentSeo.description} />
          <meta name="keywords" content={currentSeo.keywords} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charSet="UTF-8" />
        </Head>

        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=G-WJJLLTGJB4`}
        />
        {/* Google Tag Manager */}
        <Script id="gtm-init">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MZ697FNQ');
          `}
        </Script>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-WJJLLTGJB4"
        ></Script>
        <Script id="google-analytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-WJJLLTGJB4');
          `}
        </Script>

        <Component {...pageProps} />
      </>
    </Provider>
  );
}

export default wrapper.withRedux(MyApp);
